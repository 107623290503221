/**
 * Label
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './Label.css';

const Label = ({
  children, className, alignment, ...rest
}) => (
  <label {...rest} className={classnames(css['form-label'], css[`form-label--${alignment}`])}> { /* eslint-disable-line */ }
    {children}
  </label>
);

Label.propTypes = {
  alignment: PropTypes.oneOf(['start', 'center', 'end']),
  children: PropTypes.node,
  className: PropTypes.string,
};

Label.defaultProps = {
  alignment: 'start',
};

export default Label;
