/**
 * SubmitData
 */

/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import {
  FormGroup,
  Label,
  Input,
  Checkbox,
  Button,
  Headline,
  FileUpload,
} from '@samhaeng/components';

import AppContext from '../../contexts/AppContext';

import css from './SubmitData.css';

const SubmitData = ({ history }) => (
  <AppContext.Consumer>
    { ({ submitData, submitting }) => {
      const onSubmit = values => submitData(values, () => {
        window.scrollTo(0, 0);
        history.replace('/thank-you');
      });

      return (
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, pristine, invalid }) => ( /* eslint-disable-line */
            <form onSubmit={handleSubmit}>
              <Headline tagName="h1" size="large" marginBottom="extra-large" centered bold>Metadata (optional)</Headline>
              {/* <FormGroup>
                <Label alignment="center" htmlFor="input-photo">Photo</Label>
                <Field
                  name="photo"
                  render={({ input }) => (
                    <FileUpload onChange={(files) => input.onChange(files[0])} fileInputProps={{ accept: 'image/*' }}>
                      { ({ onClick, selected }) => (
                        <Button onClick={onClick}>
                          { selected ? 'Photo chosen' : 'Take photo' }
                        </Button>
                      ) }
                    </FileUpload>
                  )}
                />
              </FormGroup> */}
              <FormGroup>
                <Label alignment="center" htmlFor="input-first-name">First name</Label>
                <Field
                  name="first_name"
                  type="text"
                  render={({ input }) => <Input {...input} id="input-first-name" />}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-last-name">Last name</Label>
                <Field
                  name="last_name"
                  type="text"
                  render={({ input }) => <Input {...input} id="input-last-name" />}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-email">Email</Label>
                <Field
                  name="email"
                  render={({ input }) => <Input {...input} id="input-email" type="email" />}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-phone">Phone</Label>
                <Field
                  name="phone"
                  render={({ input }) => <Input {...input} id="input-phone" type="tel" />}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-institution">Institution (and branch)</Label>
                <Field
                  name="institution"
                  render={({ input }) => <Input {...input} id="input-institution" type="text" />}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-jobtitle">Job title and department</Label>
                <Field
                  name="job_title"
                  render={({ input }) => <Input {...input} id="input-jobtitle" type="text" />}
                />
              </FormGroup>
              <FormGroup className={css.agreement}>
                <Label>
                  <Field
                    name="marketing_agreement"
                    type="checkbox"
                    render={({ input }) => (
                      <Checkbox
                        {...input}
                        id="checkbox-agreement"
                        label="Samhæng may reach out to me with clarifying or additional questions related to today&rsquo;s interview"
                      />
                    )}
                  />
                </Label>
              </FormGroup>
              <Button type="submit" disabled={submitting}>
                { submitting ? 'Please wait..' : 'Consent and save' }
              </Button>
            </form>
          )}
        />
      );
    }}
  </AppContext.Consumer>
);

SubmitData.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default SubmitData;
