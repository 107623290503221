exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/**\n * SubmitData\n */\n\n .agreement---3Et9z {\n   margin: 2rem 0;\n }", "", {"version":3,"sources":["/Users/rasmuswoelk/Projects/_samhaeng/samhaeng-research-form/src/routes/SubmitData/SubmitData.css"],"names":[],"mappings":"AAAA;;GAEG;;CAEF;GACE,eAAe;EAChB","file":"SubmitData.css","sourcesContent":["/**\n * SubmitData\n */\n\n .agreement {\n   margin: 2rem 0;\n }"],"sourceRoot":""}]);

// exports
exports.locals = {
	"agreement": "agreement---3Et9z"
};