/**
 * Logo
 */

/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './Logo.css';

const Logo = ({ size, className }) => (
  <svg className={classnames(css.logo, className, { [css.size]: size })} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146 22">
    <path d="M48 12.6L43.2 1.4H38v19.3h3.2V6.2L46 17.1h2.9l4.9-10.7v14.3h3.9V1.4h-4.6zM9.9 9.6l-1.4-.7c-1.2-.6-2.9-1.4-2.9-2.8 0-1.8 1.9-2.4 3.4-2.4 2.3 0 4.7.9 4.7.9V1.5s-2-.5-4.9-.5C5 1 1.6 2.8 1.6 6.5c0 2.7 2.2 4.1 5 5.5l1.3.6c1.2.6 3.2 1.5 3.2 2.9 0 2.1-1.9 2.8-3.6 2.8-2.7 0-5.6-1.4-5.6-1.4v3.4s3 .7 5.5.7c3.7 0 7.8-1.6 7.8-6 0-2.7-2.4-4-5.3-5.4zM23.5 1.4l-6.9 19.3H20l1.7-5h7.4l1.7 5H35L28 1.4h-4.5zM22.6 13l2.8-8.3 2.8 8.3h-5.6zM74.6 9.4h-8.1v-8h-4v19.3h4v-8.4h8.1v8.4h4V1.4h-4zM140.4 11.9V18s-.8.2-1.6.2c-4.3 0-6.7-3.1-6.7-7.2 0-3.8 1.9-7.3 6.4-7.3 2.1 0 5.4 1.2 5.4 1.2V1.7s-2.5-.7-5.8-.7c-6.5 0-10.3 3.7-10.3 10.1 0 6.4 3.8 9.9 10.4 9.9 2.9 0 6.3-.9 6.3-.9v-8.2h-4.1zM121.1 14.7l-9.5-13.3H108v19.3h3.3V7.3l9.4 13.4h3.6V1.4h-3.2zM96.7 12.2h6.5V9.5h-6.9l-.8-5.4h8.4V1.4H89.4l-8.3 19.3h3.7l2.2-5.5h6.2l.8 5.5h10.4V18h-6.9l-.8-5.8zm-8.6.3l3.5-8.6 1.2 8.6h-4.7z" />
  </svg>
);

Logo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

export default Logo;
