/**
 * Checkbox
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import css from './Checkbox.css';

const Checkbox = ({
  className, id, label, ...rest
}) => (
  <label htmlFor={id} className={classnames(css.checkbox, className)}>
    <input
      type="checkbox"
      {...rest}
      className={classnames('sr-onsly', css.checkbox__input)}
      id={id}
    />
    <span className={css.checkbox__square}>
      <Icon icon="checkmark" className={css.checkbox__square__checkmark} />
    </span>
    <span className={css.checkbox__label}>
      {label}
    </span>
  </label>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
};

export default Checkbox;
