/**
 * RadioButton
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import css from './RadioButton.css';

const RadioButton = ({
  className, id, label, ...rest
}) => (
  <label htmlFor={id} className={classnames(css.radioButton, className)}>
    <input
      type="radio"
      {...rest}
      className={classnames('sr-onsly', css.radioButton__input)}
      id={id}
    />
    <span className={css.radioButton__circle}>
      <span className={css.radioButton__circle__dot} />
    </span>
    <span className={css.radioButton__label}>
      {label}
    </span>
  </label>
);

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
};

export default RadioButton;
