exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/**\n * PreForm\n */\n\n .checkboxes---5JZKe {\n   margin: 1.5rem 0;\n }", "", {"version":3,"sources":["/Users/rasmuswoelk/Projects/_samhaeng/samhaeng-research-form/src/routes/PreForm/PreForm.css"],"names":[],"mappings":"AAAA;;GAEG;;CAEF;GACE,iBAAiB;EAClB","file":"PreForm.css","sourcesContent":["/**\n * PreForm\n */\n\n .checkboxes {\n   margin: 1.5rem 0;\n }"],"sourceRoot":""}]);

// exports
exports.locals = {
	"checkboxes": "checkboxes---5JZKe"
};