/**
 * PreForm
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import {
  FormGroup,
  Label,
  Input,
  Checkbox,
  Button,
  Select,
  Icon,
  Headline,
} from '@samhaeng/components';

import AppContext from '../../contexts/AppContext';

import css from './PreForm.css';

const PreForm = ({ history }) => (
  <AppContext.Consumer>
    { ({
      saveSharedData, sharedValues, generateSharedLink, generatingLink, generatedLink,
    }) => {
      const onSubmit = (values) => {
        saveSharedData(values);
        history.push('/agreement');
        window.scrollTo(0, 0);
      };

      const projects = ['FOLIO', 'TrueLinked', 'ReShare', 'SHARE-VDE'];
      const interviewers = ['Filip', 'Stephanie', 'David'];

      const initialValues = {
        ...sharedValues,
        interviewer: sharedValues.interviewer || interviewers[0],
        project: sharedValues.project || projects[0],
      };

      return (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, pristine, reset, values }) => ( /* eslint-disable-line */
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label alignment="center" htmlFor="input-project">Project</Label>
                <Field
                  name="project"
                  render={({ input }) => (
                    <Select {...input} id="input-project">
                      { projects.map(project => (<option key={project}>{project}</option>))}
                    </Select>
                  )}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-topic">Topic</Label>
                <Field
                  name="topic"
                  type="text"
                  render={({ input }) => <Input {...input} id="input-topic" />}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-date">Date</Label>
                <Field
                  name="date"
                  render={({ input }) => <Input {...input} id="input-date" type="date" />}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-topic">Location</Label>
                <Field
                  name="location"
                  type="text"
                  render={({ input }) => <Input {...input} id="input-location" />}
                />
              </FormGroup>
              <FormGroup>
                <Label alignment="center" htmlFor="input-interviewer">Interviewer</Label>
                <Field
                  name="interviewer"
                  render={({ input }) => (
                    <Select {...input} id="input-interviewer">
                      { interviewers.map(interviewer => (<option key={interviewer}>{interviewer}</option>))}
                    </Select>
                  )}
                />
              </FormGroup>
              <FormGroup className={css.checkboxes}>
                <Field
                  name="format"
                  type="checkbox"
                  value="video"
                  render={({ input }) => <Checkbox {...input} id="checkbox-video" label="Video" />}
                />
                <Field
                  name="format"
                  type="checkbox"
                  value="audio"
                  render={({ input }) => <Checkbox {...input} id="checkbox-audio" label="Audio" />}
                />
              </FormGroup>
              <FormGroup>
                <Button type="submit">
                  Continue
                </Button>
              </FormGroup>
              <FormGroup>
                <Button
                  onClick={() => {
                    generateSharedLink(values, () => {
                      // (callback) Reset form to it's current values to make it pristine
                      reset(values);
                    });
                  }}
                  disabled={generatingLink}
                >
                  <Icon icon="external-link">
                    { generatingLink ? 'Generating...' : 'Generate link' }
                  </Icon>
                </Button>
              </FormGroup>
              { generatedLink && (
                <Headline centered>
                  <a href={`https://${generatedLink}`} target="_blank" rel="noopener noreferrer">{generatedLink}</a>
                  {' '}
                  {!pristine && <span>(outdated)</span>}
                </Headline>
              )}
            </form>
          )}
        />
      );
    }}
  </AppContext.Consumer>
);

PreForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default PreForm;
