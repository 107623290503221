/**
 * Icon
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Icon.css';
import icons from './icons';

const Icon = ({
  icon,
  children,
  ariaLabel,
  iconClassName,
  id,
  size,
  className,
  iconPosition,
}) => {
  const getRootClass = classNames(
    css.icon,
    className,
    // If icon position is defined
    { [css[`icon-position-${iconPosition}`]]: children && iconPosition },
    // Icons that contains "left" or "right should flip on dir="rtl"
    { [css.flippable]: icon.match(/(end|start)/) },
  );

  const getSVGClass = classNames(
    iconClassName,
    `icon-${icon}`,
    css[size],
  );

  // Defaults to the default-icon
  let IconSVG = icons.default;

  if (typeof icons[icon] === 'function') {
    IconSVG = icons[icon];
  }

  return (
    <span
      className={getRootClass}
      tabIndex="-1"
      aria-label={ariaLabel}
      id={id}
    >
      <IconSVG className={getSVGClass} />
      { children ? <span className={css.label}>{children}</span> : null }
    </span>
  );
};

Icon.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(icons)),
  iconClassName: PropTypes.string,
  iconPosition: PropTypes.oneOf([
    'start',
    'end',
  ]),
  id: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large',
  ]),
};

Icon.defaultProps = {
  size: 'medium',
  iconPosition: 'start',
  icon: 'default',
};

export default Icon;
