/**
 * Button
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'react-router-dom/Link';
import css from './Button.css';

const Button = ({
  className, children, to, ...rest
}) => {
  let Tag = 'button';

  const props = {
    ...rest,
    className: classnames(
      css.button,
      className,
    ),
  };

  /**
   * If "to"-prop is supplied; return react route <Link>
   */
  if (to) {
    Tag = Link;
    props.to = to;
  }

  return (
    <Tag
      {...props}
    >
      <span className={css.button__inner}>
        {children}
      </span>
    </Tag>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
