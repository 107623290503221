/**
 * Agreement
 */
/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../contexts/AppContext';

import {
  Headline,
  Button,
} from '@samhaeng/components';

const Agreement = () => (
  <AppContext.Consumer>
    { ({ sharedValues: { project } }) => { 
      return (
        <Fragment>
          <Headline tagName="h1" size="large" marginBottom="extra-large" centered bold>On data</Headline>
          <Headline tagName="h3" centered bold>How we use the data</Headline>
          <Headline tag="p" marginBottom="large" centered>
            We use the the multimedia content gathered during this interview to learn about how we can invent or improve the user experience and user interfaces related to the {project} project. In the next step, we will ask for your name, job title and other metadata. It is optional whether you want to supply this. We use that information to remember which multimedia files are connected with which interviewee.
          </Headline>
          <Headline tagName="h3" centered bold>Who accesses the data</Headline>
          <Headline tag="p" marginBottom="large" centered>
            The data gathered during this interview will only ever be accessed by UX researchers and UX designers of Samhæng (Meaning employees of, and long-term consultants with, Samhæng ApS)
          </Headline>
          <Headline tagName="h3" centered bold>Questions about the data</Headline>
          <Headline tag="p" marginBottom="large" centered>
            At any time you can contact us to learn what data we have stored about you, and which specific Samhæng team members have had access to it.
          </Headline>
          <Headline tagName="h3" centered bold>Deletion of data</Headline>
          <Headline tag="p" marginBottom="large" centered>
            At any time you may contact us to request that we delete any or all of your data.
          </Headline>
          <Button to="/submit-data" onClick={() => { window.scrollTo(0, 0); }}>
            I understand and agree
          </Button>
        </Fragment>
      );
    }}
  </AppContext.Consumer>
);

export default Agreement;
