/**
 * Input
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import css from './Input.css';

const Input = ({
  className, inputClassName, ...rest
}) => {
  const rootClassNames = classnames(css.root, className);
  const inputClassNames = classnames(css.input, inputClassName);

  return (
    <div className={rootClassNames}>
      <input
        {...rest}
        className={inputClassNames}
      />
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

Input.defaultProps = {
  className: '',
  inputClassName: '',
};

export default Input;
