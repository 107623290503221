/**
 * Thank You
 */

/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../contexts/AppContext';

import {
  Headline,
  Button,
} from '@samhaeng/components';

const ThankYou = () => (
  <AppContext.Consumer>
    { ({ sharedValues: { interviewer } }) => { 
      return (
        <Fragment>
          <Headline tagName="h1" size="large" marginBottom="large" centered bold>Thank you</Headline>
          <Headline tagName="h3" centered>Please hand the phone back to { interviewer }</Headline>
          <br />
          <Button to="/" onClick={() => { window.scrollTo(0, 0); }}>
            Done
          </Button>
        </Fragment>
      );
    }}
  </AppContext.Consumer>
);

export default ThankYou;
