/* eslint-disable */
import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './styles/app.css';

import PreForm from './routes/PreForm';
import Agreement from './routes/Agreement';
import SubmitData from './routes/SubmitData';
import ThankYou from './routes/ThankYou';

import App from './App';

const Root = () => (
  <Router>
    <App>
      <Route path="/" exact component={PreForm} />
      <Route path="/agreement" exact component={Agreement} />
      <Route path="/submit-data" exact component={SubmitData} />
      <Route path="/thank-you" exact component={ThankYou} />
    </App>
  </Router>
);

export default Root;
