/* eslint-disable */
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { Link, withRouter } from "react-router-dom";
import './styles/app.css';
import AppContext from './contexts/AppContext';

import PreForm from './routes/PreForm';
import Agreement from './routes/Agreement';
import SubmitData from './routes/SubmitData';
import ThankYou from './routes/ThankYou';

import css from './App.css';

import {
  Logo,
} from '@samhaeng/components';
class App extends Component {
  constructor(props) {
    super(props);

    this.storageKey = 'research-form-storage';

    this.state = {
      ready: false, // App is ready
      sharedValues: {},
      submitting: false,
    }

    this.hashKey = 'some-random-key';
  }


  componentDidMount() {
    let sharedValues = {};
    console.log(this.props);

    // If we came from a shared link we will use this to autofill the data
    const autofillValues = this.getAutoFillValues();
    if (autofillValues) {
      sharedValues = autofillValues;
      // Save the new values in localstorage
      localStorage.setItem(this.storageKey, JSON.stringify(sharedValues));

      // Clear URL
      this.props.history.replace(this.props.location.pathname);

    // Else we look for persisted values
    } else {
      // Sync any saved data in local storage
      const persistedValues = localStorage.getItem(this.storageKey);
      
      // if we have persisted values
      if (persistedValues) {
        sharedValues = JSON.parse(persistedValues);
      }
      
      // Auto-fill todays date into date field
      sharedValues.date = moment().format('YYYY-MM-DD');
    }

    this.setState({
      sharedValues,
      ready: true,
    });
  }

  /**
   * Saves persisted information
   * entered in the initial form
   */
  saveSharedData = (data) => {
    this.setState(() => ({
      sharedValues: data,
    }), () => {
      localStorage.setItem(this.storageKey, JSON.stringify(this.state.sharedValues));
    });
  }

  /**
   * Get context values that can 
   * be passed to child components
   */
  getContextValues = () => {
    const { saveSharedData, submitData, generateSharedLink } = this;
    const { sharedValues, submitting, generatingLink, generatedLink } = this.state;
    return {
      generateSharedLink,
      generatingLink,
      generatedLink,
      saveSharedData,
      sharedValues,
      submitData,
      submitting,
    };
  }

  /**
   * Get autofill values from shared URL
   */
  getAutoFillValues = () => {
    const { location: { search } } = this.props;

    // Check if we have ?{hash} in the URL
    if (search && search.charAt(0) === '?') {
      // Convert hash to data if possible
      const data = this.convertUrlHashToData(search.substr(1));
      if (data) {
        return data;
      }
    }

    return null;
  }

  /**
   * Generate shareable link
   */
  generateSharedLink = (values, callback) => {
    this.setState({ generatingLink: true });
    const url = `${window.location.origin}/agreement/?${this.generateUrlHash(values)}`;

    axios.get('https://api.research-form.samhaeng.com', {
      params: {
        url,
      }
    }).then((res) => {
    this.setState({
      generatingLink: false,
      generatedLink: res.data.url,
    });

    if (typeof callback === 'function') {
      callback(res.data.url);
    }
    }).catch((res) => {
      console.error('Error generating url', res);
    });

  }

  // Generate hash from form data
  generateUrlHash = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.hashKey);
  }

  // Convert URL hash to data
  convertUrlHashToData = (ciphertext) => {
    const bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), this.hashKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    
    try {
      return JSON.parse(plaintext);
    } catch (e) {
      return null;
    }
  }

  /**
   * Submit and save data
   */
  submitData = (formValues, callback) => {
    const { sharedValues } = this.state;
    const data = {
      ...formValues,
      ...sharedValues,
    }
    
    let formData = new FormData();

    // Append photo if we have one
    if (data.photo) {
      formData.append('photo', data.photo);
    }

    // Append the rest of the data
    Object.keys(data).filter(key => key !== 'photo').map(key => {
      formData.append(key, data[key]);
    });

    this.setState({
      submitting: true,
    });

    axios({
      method: 'post',
      // url: process.env.NODE_ENV === 'production' ? 'https://api.research-form.samhaeng.com' : 'https://samhaeng-research-form.test/api',
      url: 'https://api.research-form.samhaeng.com/',
      timeout: 10000,
      withCredentials: false,
      headers: {
        'accept': 'application/json',
        'content-type': 'multipart/form-data',
      },
      data: formData,
    }).then(response => {
      console.log('Response', response);
      this.setState({
        submitting: false,
      });
      callback();
    }).catch(error => {
      this.setState({
        submitting: false,
      });
      console.error('Error submitting data', error);
    });

  }

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider value={this.getContextValues()}>
          <div className={css.root}>
            <Link to="/">
              <Logo className={css.logo} />
            </Link>
            {children}
          </div>
      </AppContext.Provider>
    );
  }
}

export default withRouter(App);
