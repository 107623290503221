/**
 * FileUpload
 */

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import css from './FileUpload.css';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.fileInput = createRef();

    this.state = {
      files: null,
    };
  }

  onClick = () => {
    this.fileInput.current.click();
  }

  onInputChange = (e) => {
    const { onChange } = this.props;

    this.setState({
      files: e.target.files,
    });

    onChange(e.target.files);
  }

  render() {
    const { children, fileInputProps } = this.props;
    const { files } = this.state;

    const childComponentProps = {
      onClick: this.onClick,
      selected: !!files,
    };

    return (
      <div>
        <input
          {...fileInputProps}
          type="file"
          ref={this.fileInput}
          className={css.FileUpload__fileInput}
          onChange={this.onInputChange}
        />
        { children(childComponentProps) }
      </div>
    );
  }
}

FileUpload.propTypes = {
  children: PropTypes.func,
  fileInputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default FileUpload;
