/**
 * Select
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import css from './Select.css';

const Select = ({ className, children, ...rest }) => (
  <div className={classnames(css.select, className)}>
    <select {...rest} className={css.select__input}>
      {children}
    </select>
    <Icon
      className={css.select__arrow}
      icon="caret-down"
    />
  </div>
);

Select.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Select;
