exports = module.exports = require("../../../../samhaeng-research-form/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/**\n * FileUpload\n */\n\n.FileUpload__fileInput---35flk {\n  position: absolute;\n  clip: rect(0, 0, 0, 0);\n  white-space: nowrap;\n  -webkit-clip-path: inset(50%);\n          clip-path: inset(50%);\n  border: 0;\n  height: 0;\n  width: 0;\n}", "", {"version":3,"sources":["/Users/rasmuswoelk/Projects/_samhaeng/samhaeng-components/src/components/FileUpload/FileUpload.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,8BAAsB;UAAtB,sBAAsB;EACtB,UAAU;EACV,UAAU;EACV,SAAS;CACV","file":"FileUpload.css","sourcesContent":["/**\n * FileUpload\n */\n\n.FileUpload__fileInput {\n  position: absolute;\n  clip: rect(0, 0, 0, 0);\n  white-space: nowrap;\n  clip-path: inset(50%);\n  border: 0;\n  height: 0;\n  width: 0;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"FileUpload__fileInput": "FileUpload__fileInput---35flk"
};