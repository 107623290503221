/**
 * Headline
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './Headline.css';

const Headline = ({
  children, className, tagName, centered, bold, size, marginBottom, ...rest
}) => {
  const Tag = tagName;
  const classNames = classnames(
    css.headline,
    className,
    css[`size-${size}`],
    { [css[`margin-bottom-${marginBottom}`]]: marginBottom },
    { [css.centered]: centered },
    { [css.bold]: bold },
  );
  return (
    <Tag {...rest} className={classNames}>
      { children }
    </Tag>
  );
};

Headline.propTypes = {
  bold: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  marginBottom: PropTypes.oneOf([undefined, 'small', 'medium', 'large', 'extra-large']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-large']),
  tagName: PropTypes.string,
};

Headline.defaultProps = {
  tagName: 'div',
  size: 'medium',
};


export default Headline;
