exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/**\n * App\n */\n\n.root---3-5HS {\n  padding: 1rem 1rem 3rem 1rem;\n  max-width: 37rem;\n  margin: auto;\n}\n\n.logo---3CJMU {\n  height: 0.8rem;\n  margin: 0 auto 2.5rem auto;\n  display: block;\n  color: #000;\n}", "", {"version":3,"sources":["/Users/rasmuswoelk/Projects/_samhaeng/samhaeng-research-form/src/App.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,aAAa;CACd;;AAED;EACE,eAAe;EACf,2BAA2B;EAC3B,eAAe;EACf,YAAiC;CAClC","file":"App.css","sourcesContent":["/**\n * App\n */\n\n.root {\n  padding: 1rem 1rem 3rem 1rem;\n  max-width: 37rem;\n  margin: auto;\n}\n\n.logo {\n  height: 0.8rem;\n  margin: 0 auto 2.5rem auto;\n  display: block;\n  color: var(--base-content-color);\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "root---3-5HS",
	"logo": "logo---3CJMU"
};