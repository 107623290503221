/**
 * FormGroup
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './FormGroup.css';

const FormGroup = ({ children, className, ...rest }) => (
  <div {...rest} className={classnames(css['form-group'], className)}>
    {children}
  </div>
);

FormGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FormGroup;
